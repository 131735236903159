import { Dispatch, SetStateAction, useEffect } from 'react';

import { LoginModalBody } from './components/loginModalBody';
import { Modal } from '../components/modal';
import { ReactPortal } from '../../reactPortal';
import { ThemeLogoType } from '../../themeLogo';
import { setIsOpenLoginModalValue } from 'features/modal/modalSlice';
import { useAppDispatch } from 'lib/centralStore';
import { useGetLabelByKey } from 'hooks/useLingUI';
import { useRouter } from 'next/router';

export type LoginModalProps = {
  logoBrand?: ThemeLogoType;
  isOpen?: boolean;
  handleClose: Dispatch<SetStateAction<boolean>>;
};

export const LoginModal = ({ logoBrand, isOpen, handleClose }: LoginModalProps) => {
  const btnLabel = useGetLabelByKey('entra')!;

  const loginLabel = useGetLabelByKey('login')!;
  const router = useRouter();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (router.pathname === '/totocalcio') {
      dispatch(setIsOpenLoginModalValue(false));
    }
  }, [router.pathname]);
  if (!isOpen) return null;

  return (
    <ReactPortal wrapperId="loginModal">
      <Modal label={loginLabel} logoBrand={logoBrand} modalType="primary" modalCategory="login-modal">
        <LoginModalBody title={loginLabel} buttonLabel={btnLabel!} handleClose={handleClose} />
      </Modal>
    </ReactPortal>
  );
};
