import { GamesCard, GamesCardType } from '../gamesCard';
import { SlotCardDescription, SlotCardDescriptionType } from './slotCardDescription';

export type SlotCardProps = GamesCardType & SlotCardDescriptionType;

export const SlotCard = (props: SlotCardProps) => (
  <GamesCard {...props}>
    {props.functionality && props.functionality?.length > 0 && props.volatility?.value && (
      <SlotCardDescription
        functionality={props.functionality ? props.functionality : []}
        volatilityLabel={'Vol.'}
        volatilityValue={props.volatility ? props.volatility.value : ''}
      />
    )}
  </GamesCard>
);
