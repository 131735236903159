import dynamic from 'next/dynamic';

import type { PersonalAreaModalProps as SnaiProps } from 'sites/snai/components/personalAreaModal/PersonalAreaModal';
import type { PersonalAreaModalProps as HpybetProps } from 'sites/happybet/components/personalAreaModal/PersonalAreaModal';
import { isHappybetSite } from 'utility/constant';

const PersonalAreaModal = dynamic<HpybetProps | SnaiProps>(
  () =>
    import(`sites/${isHappybetSite ? 'happybet' : 'snai'}/components/personalAreaModal/PersonalAreaModal`).then(
      (mod) => mod.PersonalAreaModal
    ),
  { ssr: false }
);

export { PersonalAreaModal };
