import { Icon, IconsEnum } from 'components/Icons';
import { useMemo, useState } from 'react';

import { Button } from 'components/button';
import { DatoCmsModalTutorialType } from 'lib/datoCms/types';
import Image from 'next/image';
import { Modal } from 'components/modals/components/modal';
import { ReactPortal } from 'components/reactPortal';
import { RichText } from 'components/richText';
import { SimpleCheckbox } from 'components/inputs/simpleCheckbox';
import { SwitchTheme } from 'components/footer/switchTheme';
import { breakpoint } from 'utility/constant';
import dynamic from 'next/dynamic';
import { setIsOpenNewsDialog } from 'features/modal/modalSlice';
import styles from './NewsDialog.module.scss';
import { useAppDispatch } from 'lib/centralStore';
import { useTheme } from 'next-themes';
import useWindowSize from 'hooks/useWindowSize';

export const DynamicNewsDialog = dynamic(() => import('components/newsDialog').then((mod) => mod.NewsDialog));

export type NewsDialogProps = DatoCmsModalTutorialType & {
  isMobileFullScreen?: boolean;
  hasClickOutside?: boolean;
  onHideModal: (value: boolean) => void;
};

export const NewsDialog = ({
  modalType,
  image,
  title,
  subtitle,
  description,
  switchTheme,
  ctaLabel,
  checkbox,
  isMobileFullScreen,
  ...props
}: NewsDialogProps) => {
  const [hide, setHide] = useState<boolean>(false);
  let typeSvg: Exclude<typeof modalType, 'info'>;
  const typeIconDictionary: { [key in typeof typeSvg]: IconsEnum } = {
    error: IconsEnum.ECLAMATION_MARK_OUTLINE,
    success: IconsEnum.CHECK_GREEN,
    warning: IconsEnum.INFO,
  };

  const { theme } = useTheme();
  const isDarkMode = () => theme === 'dark';
  const img = useMemo(() => {
    if (image) {
      return image[0];
    } else {
      return;
    }
  }, [image]);
  const { width } = useWindowSize();
  const dispatch = useAppDispatch();

  const isMobile = width! < breakpoint.md;
  return (
    <ReactPortal wrapperId="modalTutorial">
      <Modal
        label=""
        modalType="modalTutorial"
        modalCategory="modalTutorial"
        onClose={() => dispatch(setIsOpenNewsDialog(false))}
      >
        <div className={`${styles.container} ${modalType === 'info' ? styles.layoutImage : ''} `}>
          {img && modalType === 'info' && (
            <div className={styles.imageContainer}>
              <Image
                priority
                className={styles.image}
                src={isDarkMode() ? img.logoDarkMode.url : img.logoLightMode.url}
                alt={img.logoDarkMode.alt ?? ''}
                title={img.logoDarkMode.title}
                fill
                loading="eager"
                style={{ objectFit: 'cover' }}
              />
            </div>
          )}
          <div className={`${styles.contentAndActionsContainer} ${!img ? styles.fullWidth : ''}`}>
            <div className={styles.textContainer}>
              {modalType !== 'info' && (
                <span className={styles.iconContainer}>
                  <Icon iconId={typeIconDictionary[modalType]} className={styles.icon} />
                </span>
              )}
              <div className={styles.title}>{title}</div>
              {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
              <div className={styles.descriptionContainer}>
                <RichText containerClassName={styles.description}>{description}</RichText>
              </div>
              {!!switchTheme?.length && (
                <div className={styles.switchThemeContainer}>
                  <SwitchTheme labelLight={switchTheme[0].labelLight} labelDark={switchTheme[0].labelDark} />
                </div>
              )}
            </div>
            <div className={styles.actionsContainer}>
              {checkbox && (
                <SimpleCheckbox
                  className={styles.simpleCheckbox}
                  value={checkbox[0].value}
                  label={checkbox[0].label}
                  handleFilterChange={(el: HTMLInputElement) => {
                    setHide(el.checked);
                  }}
                />
              )}
              <div className={styles.actionsPrimaryAndSecondaryContainer}>
                <Button
                  visualizationType="secondary"
                  size="medium"
                  className={styles.ctaPrimary}
                  onClick={() => {
                    dispatch(setIsOpenNewsDialog(false));
                    hide && props.onHideModal(hide);
                  }}
                >
                  {ctaLabel}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </ReactPortal>
  );
};
