import styles from './CardImageFooter.module.scss';

export type CardImageFooterProps = {
  children: React.ReactNode;
  hidden?: boolean;
};

export const CardImageFooter = ({ children, hidden }: CardImageFooterProps) => {
  return <div className={`${styles.container} ${hidden ? styles.hide : ''}`}>{children}</div>;
};
