import { Icon, IconsEnum } from '../../../Icons';

import { BackgroundImage } from 'components/backgroundImage';
import styles from './CardNotAvailableImage.module.scss';
import { useGetLabelByKey } from 'hooks/useLingUI';

export interface CardNotAvailableImageProps {
  imageId?: IconsEnum;
  label?: string;
}

export const CardNotAvailableImage = ({ imageId = IconsEnum.IMAGE, label }: CardNotAvailableImageProps) => {
  const labelImgNonDisponibile = useGetLabelByKey('not-available-image')!;

  return (
    <div className={styles.container}>
      <BackgroundImage imageClassName={styles.logo} />
      <Icon iconId={imageId} className={styles.icon} color={'var(--color-on-bg-secondary)'} />
      <p className={styles.text}>{label || labelImgNonDisponibile}</p>
    </div>
  );
};
