import { useEffect, useState } from 'react';

import { breakpoint } from '../utility/constant';
import useWindowSize from './useWindowSize';

export const useCodeGameByWindowSize = (codeGameDesktop?: string, codeGameMobile?: string) => {
  const [codeGame, setCodeGame] = useState<string>('');
  const { width } = useWindowSize();
  const desktopSize = breakpoint.lg;

  useEffect(() => {
    const nextValue = codeGameMobile && width && width < desktopSize ? codeGameMobile : codeGameDesktop;
    setCodeGame(nextValue ?? '');
  }, [codeGameDesktop, codeGameMobile, desktopSize, width]);

  return [codeGame];
};
