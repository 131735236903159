import { isClientSide } from 'utility/functions';
import { useMemo } from 'react';
export interface AgentDetectType {
  isMac: boolean;
  isIos: boolean;
  isMobile: boolean;

  isEdge: boolean;
  isSafari: boolean;
  isChrome: boolean;
  isIPhone: boolean;
  isFirefox: boolean;
}

export default function useAgentDetect() {
  const { userAgent } = isClientSide() ? Reflect.get(window ?? {}, 'navigator') : { userAgent: 'server-render' };

  return useMemo(() => {
    const isIos = Boolean(userAgent?.match(/(iPod|iPad|iPhone)/i));
    const isMac = Boolean(userAgent?.match(/(Mac|iPod|iPad|iPhone)/i));
    const isMobile = Boolean(userAgent?.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i));

    const isEdge = userAgent?.includes('Edge');
    const isChrome = userAgent?.includes('Chrome');
    const isIPhone = userAgent?.includes('iPhone');
    const isSafari = userAgent?.includes('Safari');
    const isFirefox = userAgent?.includes('Firefox');

    // console.log(`useAgentDetect("${userAgent}") -> isMac:${isMac}, isIos:${isIos}, isMobile:${isMobile}, isFirefox:${isFirefox}, isChrome:${isChrome}, isIPhone:${isIPhone}, isSafari:${isSafari}, isEdge:${isEdge}`)

    return { isMac, isIos, isMobile, isFirefox, isChrome, isIPhone, isSafari, isEdge };
  }, [userAgent]);
}
