import { Icon, IconsEnum } from 'components/Icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SlotCard, SlotCardProps } from '../cards/slotCard/SlotCard';
import { enComparer, useScreenWidth } from 'hooks/useScreenWidth';
import { gamePlatforms, launchGame } from 'lib/launchGame/utils';
import { useAppDispatch, useTypedSelector } from 'lib/centralStore';

import { GamesCardType } from 'components/cards/gamesCard';
import Image from 'next/image';
import Script from 'next/script';
import { appInsight } from 'components/appInsight';
import { breakpoint } from 'utility/constant';
import classNames from 'classnames';
import { getCookie } from 'lib/persist/cookie';
import { getNonce } from 'lib/policy';
import { selectInstantScriptLoaded } from 'features/configuration/selectors';
import { setInstantScriptLoaded } from 'features/configuration/configurationSlice';
import styles from './InstantPanel.module.scss';
import { useCodeGameByWindowSize } from 'hooks/useCodeGameByWindowSize';
import { useGetLabelsByKeys } from 'hooks/useLingUI';
import { useInternalSession } from 'hooks/useInternalSession';

export type InstantPanelProps = {
  instantRoulette?: GamesCardType;
  instantSlots?: SlotCardProps[];
  image: {
    id?: string;
    alt?: string;
    url: string;
  };
};

export const InstantPanel = (props: InstantPanelProps) => {
  const { instantRoulette, instantSlots, image } = props ?? {};

  const dispatch = useAppDispatch();
  const isMobile = useScreenWidth(enComparer.less, breakpoint.md);
  const isScriptLoaded = useTypedSelector(selectInstantScriptLoaded);
  const [labelInstantSlot, labelNascondiPer24h, labelInstantRoulette] = useGetLabelsByKeys([
    'instant-slot',
    'nascondi-per-24-ore',
    'instant-roulette',
  ]);

  const [imgOK, setImgOK] = useState(true);
  const [isLaunched, setIsLaunched] = useState<boolean>(false);
  const [activePanel, setActivePanel] = useState<string | null>();
  const [injectScript, setInjectScript] = useState<boolean>(false);

  const gameConfigSrc = useMemo(
    () => gamePlatforms[instantRoulette?.codicePiattaforma as keyof typeof gamePlatforms]?.urlParams.FIM_CONF,
    [instantRoulette?.codicePiattaforma]
  );
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [url, setUrl] = useState<string>();
  const [isHover, setIsHover] = useState<boolean>(false);
  const [hidden, setHidden] = useState<boolean>(false);

  const hasScriptToLoad = useMemo(() => injectScript && gameConfigSrc, [gameConfigSrc, injectScript]);

  const [codeGameRoulette] = useCodeGameByWindowSize(
    instantRoulette?.playInfo.codeGameDesktop!,
    instantRoulette?.playInfo.codeGameMobile!
  );

  const { session } = useInternalSession();

  const objToggler = useMemo(() => {
    const { url, alt } = image ?? {};

    if (url && imgOK) {
      return (
        <Image
          src={url}
          alt={alt ?? ''}
          width={40}
          height={40}
          className={styles.instantIcon}
          onError={() => setImgOK(false)}
        />
      );
    } else {
      return <Icon iconId={IconsEnum.INSTANT_PANEL} className={styles.instantIcon} />;
    }
  }, [image, imgOK]);

  useEffect(() => {
    if (!gameConfigSrc) {
      dispatch(setInstantScriptLoaded(true));
    }
  }, [dispatch, gameConfigSrc]);

  // RESET URL AND LAUNCH WHEN SESSION CHANGES
  useEffect(() => {
    if (!session && isLaunched) {
      setUrl(undefined);
      setIsLaunched(false);
    }
  }, [session, isLaunched]);

  // TRIGGER LAUNCH INSTANT GAME
  useEffect(() => {
    if (isScriptLoaded && !!session && !isLaunched && codeGameRoulette && !hidden) {
      launchGame({
        ...(instantRoulette as any),
        isInstant: true,
        isFromPanel: true,
        gameCode: codeGameRoulette,
        cardNumber: session?.user.cardNumber,
        isMobile,
      })
        .catch((error) => {
          console.error(`unable to launch game`, error);
          appInsight.trackException({
            exception: error,
            id: 'instan-panel',
            properties: {
              gameCode: codeGameRoulette,
              cardNumber: session?.user.cardNumber,
            },
          });
        })
        .then((urlResponse: string) => {
          setUrl(urlResponse);
          setIsLaunched(true);
        });
    }
  }, [session, isLaunched, instantRoulette, codeGameRoulette, isScriptLoaded, isMobile, hidden]);

  const setCookie = useCallback((cname: string, cvalue: string) => {
    let tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    let expires = 'expires=' + tomorrow.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }, []);

  useEffect(() => {
    const get = getCookie('instant-roulette-disabled');
    if (get) {
      setHidden(true);
    }
    setInjectScript(true);
  }, []);

  useEffect(() => {
    setActivePanel(!instantRoulette ? labelInstantSlot : labelInstantRoulette);
  }, [labelInstantRoulette, labelInstantSlot, instantRoulette]);

  if (!instantRoulette && !instantSlots?.length) {
    return null;
  }

  return (
    <React.Fragment>
      {!isVisible && (
        <button
          disabled={hidden}
          id="openButton"
          onClick={() => {
            setIsVisible(true);
          }}
          className={`${hidden && styles.openButtonDisabled} ${styles.openButton}`}
        >
          {objToggler}
        </button>
      )}
      <aside className={`${styles.aside} ${isVisible ? styles.visible : ''}`}>
        <div className={`${styles.container} ${isVisible ? styles.panelVisible : styles.panelHidden}`}>
          <div className={styles.tabs}>
            <div className={classNames(styles.tab, { [styles.disabled]: !instantRoulette })}>
              <h3
                onClick={() => instantRoulette && setActivePanel(labelInstantRoulette)}
                className={classNames({
                  [styles.activeTitle]: activePanel === labelInstantRoulette,
                })}
              >
                {labelInstantRoulette}
              </h3>
            </div>
            {instantSlots && (
              <div className={styles.tab} onClick={() => setActivePanel(labelInstantSlot)}>
                <h3 className={`${activePanel === labelInstantSlot && styles.activeTitle}`}>{labelInstantSlot}</h3>
              </div>
            )}
            <button
              onClick={() => {
                setIsVisible(false);
              }}
              className={styles.closeButton}
            >
              <Icon className={styles.closeIcon} iconId={IconsEnum.CLOSE} color="var(--color-on-bg-primary)" />
            </button>
          </div>

          <div className={styles.contentSection}>
            <div
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
              className={`${styles.rouletteContent} ${activePanel === labelInstantRoulette && styles.activeContent}`}
            >
              {url ? (
                <iframe src={url} height="100%" width="100%" nonce={getNonce()} />
              ) : (
                <div className={styles.card}>
                  <SlotCard {...(instantRoulette as any)} isHover={isHover}></SlotCard>
                </div>
              )}
            </div>
            {!!instantSlots?.length && (
              <div className={`${styles.slotContent} ${activePanel === labelInstantSlot && styles.activeContent}`}>
                <div className={styles.cardListContainer}>
                  {instantSlots.map((item) => {
                    return (
                      <div className={styles.card} key={item.id}>
                        <SlotCard {...{ ...item, functionality: [] }}></SlotCard>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>

          <button
            onClick={() => {
              setIsVisible(false);
              setHidden(true);
              setCookie('instant-roulette-disabled', 'true');
            }}
            className={styles.hideButton}
          >
            <Icon className={styles.hideIcon} iconId={IconsEnum.EYE_OFF} color="var(--color-on-bg-primary)" />
            <span>{labelNascondiPer24h}</span>
          </button>
        </div>
        {hasScriptToLoad && (
          <Script
            src={gameConfigSrc}
            onLoad={() => {
              dispatch(setInstantScriptLoaded(true));
            }}
          />
        )}
      </aside>
    </React.Fragment>
  );
};
