import { enComparer, useScreenWidth } from 'hooks/useScreenWidth';

import { FooterProps } from './Footer';
import { breakpoint } from 'utility/constant';
import { useLazyLoad } from 'hooks';

export type { FooterProps as FooterType } from './Footer';

export const Footer = (props: FooterProps) => {
  const height = useScreenWidth(enComparer.less, breakpoint.md) ? 760 : 700;
  return useLazyLoad({ doLoad: () => import('components/footer/Footer').then((mod) => mod.Footer), height, props });
};
