import { useGetCountMessagesQuery } from 'features/api/apiSlice';
import { useInternalSession } from 'hooks/useInternalSession';
import styles from './NewMessagesIndicator.module.scss';

export type NewMessagesIndicatorProps = {};

export const NewMessagesIndicator = ({}: NewMessagesIndicatorProps) => {
  const { status, isAuthenticated } = useInternalSession();
  const { data: messagesResponseApi } = useGetCountMessagesQuery(undefined, {
    skip: !isAuthenticated || status === 'loading',
  });

  if (!messagesResponseApi || (messagesResponseApi.count ?? '0') === '0') return null;

  return (
    <div className={styles.container}>
      <div className={styles.messages}>{messagesResponseApi.count}</div>
    </div>
  );
};
