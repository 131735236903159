import { BaseItemFilter } from 'components/scommesseFilterTertiary/ScommesseFilterTertiary';
import { useTranslation } from 'hooks/useLingUI';
import styles from './SimpleCheckbox.module.scss';

export type SimpleCheckboxProps = BaseItemFilter & {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  handleFilterChange: (_element: EventTarget & HTMLInputElement) => void;
};

export const SimpleCheckbox = ({
  value,
  label,
  checked,
  disabled,
  className,
  labelTrKey,
  handleFilterChange,
}: SimpleCheckboxProps) => {
  const { t } = useTranslation();
  return (
    <div className={`${styles.container} ${className}`}>
      <label className={styles.label}>
        <input
          id={value}
          name={value}
          type="checkbox"
          value={value}
          checked={checked}
          disabled={disabled}
          className={styles.checkbox}
          onChange={(e) => {
            handleFilterChange(e.target);
          }}
        />
        <span className={styles.checkmark}></span>
        {labelTrKey ? label && t(labelTrKey, label) : label}
      </label>
    </div>
  );
};
