import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'lib/centralStore';

const _self = (state: RootState) => state.archiveSlot;

const selectAllItems = createSelector([_self], ({ allItems }) => allItems);
export const selectAllItemsFactory = () => createSelector([selectAllItems], (allItems) => allItems);

const selectPageNumber = createSelector([_self], ({ pageNumber }) => pageNumber);
export const selectPageNumberFactory = () => createSelector([selectPageNumber], (pageNumber) => pageNumber);

const selectFiltersSelected = createSelector([_self], ({ selectedFilters }) => selectedFilters);
export const selectFiltersSelectedFactory = () =>
  createSelector([selectFiltersSelected], (selectedFilters) => selectedFilters);

const selectFiltersSelectedLength = createSelector([_self], ({ selectedFiltersLength }) => selectedFiltersLength);
export const selectFiltersSelectedLengthFactory = () =>
  createSelector([selectFiltersSelectedLength], (selectedFiltersLength) => selectedFiltersLength);

const selectUpdatingItems = createSelector([_self], ({ updatingItems }) => updatingItems);
export const selecttUpdatingItemsFactory = (id: string) =>
  createSelector([selectUpdatingItems], (updatingItems) => updatingItems.hasOwnProperty(id));
