import React, { useState } from 'react';
import { AnchorLink } from '../../anchorLink';
import { MenuItemBlock } from '../Header';
import styles from './DetailsMenu.module.scss';

export type DetailsMenuProps = {
  menuItemBlock: MenuItemBlock[];
  showDetail: boolean;
  index: number;
  currentIndex: number;
  gtagAction?: (page: string) => void;
  // currentIndex: number;
  // inEvidenceLabel?: string;
  // handleCloseDetail?: () => void;
  // dataBanner?: Banner;
};

export const DetailsMenu: React.FC<DetailsMenuProps> = ({
  showDetail,
  menuItemBlock,
  index,
  currentIndex,
  gtagAction,
}: DetailsMenuProps) => {
  const [defaultItem, setDefaultItem] = useState<boolean>(true);
  const [itemHovered, setItemHovered] = useState<string>('');
  const handleMouseEnter = (e: React.MouseEvent, text: string) => {
    if (e.currentTarget.textContent === text) {
      setItemHovered(e.currentTarget.textContent);
    } else {
      setItemHovered('');
    }
    setDefaultItem(false);
  };
  const handleMouseLeave = () => {
    setItemHovered('');
    setDefaultItem(true);
  };
  return (
    <div className={`${styles.detailsMenu} ${showDetail ? styles.show : styles.hide} ${currentIndex}`}>
      <ul className={styles.list}>
        {menuItemBlock &&
          menuItemBlock.map(({ id, label, linkBlock }: MenuItemBlock) => {
            return (
              <li
                key={id}
                className={`${styles.item} ${itemHovered === label ? styles.itemActive : styles.itemInactive} ${
                  defaultItem && styles.itemDefault
                }
                  `}
                onMouseEnter={(e) => handleMouseEnter(e, label!)}
                onMouseLeave={handleMouseLeave}
              >
                <AnchorLink
                  href={linkBlock[0].href!}
                  className={styles.link}
                  onClick={() => gtagAction && gtagAction(label!)}
                >
                  {label}
                </AnchorLink>
              </li>
            );
          })}
      </ul>
    </div>
  );
};
