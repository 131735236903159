import Image, { ImageProps } from 'next/image';

import { CardHoverMask } from '../../cardHoverMask';
import { CardNotAvailableImage } from '../../cardNotAvailableImage';
import { CardSpinner } from '../../cardSpinner/CardSpinner';
import { breakpoint } from '../../../../../utility/constant';
import { getSrcImage } from '../../../utils';
import styles from './CardImageContainer.module.scss';
import { useGetLabelByKey } from 'hooks/useLingUI';
import { useIsAuthenticated } from 'hooks/useIsAuthenticated';
import { useMemo } from 'react';
import useWindowSize from 'hooks/useWindowSize';

export type CardImageContainerProps = {
  image?: ImageProps & {
    srcThumbnail: string;
    srcCover: string;
  };
  children?: React.ReactNode;
  imageClass?: string;
  bgColor?: string;
  playLabel?: string;
  isHover: boolean;
  handleHover?: React.Dispatch<React.SetStateAction<boolean>>;
  showImage?: boolean;
  isCover: boolean;
  hasGamePlatform?: boolean;
  handleLaunchGame: () => void;
  hasSpinner?: boolean;
};

export const CardImageContainer = ({
  image,
  children,
  imageClass,
  bgColor,
  isHover,
  handleHover,
  isCover,
  hasGamePlatform,
  showImage = true,
  handleLaunchGame,
  hasSpinner = false,
}: CardImageContainerProps) => {
  const { width } = useWindowSize();
  const type = width && width < breakpoint.lg ? 'linked' : 'basic';
  const labelGioca = useGetLabelByKey('gioca');
  const haveImg: boolean =
    (image?.srcThumbnail !== undefined && image?.srcThumbnail !== '') ||
    (image?.srcCover !== undefined && image?.srcCover !== '');

  const srcImage = useMemo(
    () => (image?.srcThumbnail || image?.srcCover) && getSrcImage(isCover, image),
    [isCover, image]
  );
  const isAuthenticated = useIsAuthenticated();

  if (type === 'basic') {
    return (
      <div
        onMouseEnter={() => handleHover!(true)}
        onMouseLeave={() => handleHover!(false)}
        className={`${styles.container} ${imageClass ? imageClass : ''}`}
        style={{
          backgroundColor: bgColor ?? 'transparent',
        }}
      >
        {image && haveImg ? (
          <div className={`${styles.imgContainer} ${isAuthenticated && styles.imgHasLinearGradient} `}>
            <Image className={styles.image} src={srcImage!} alt={image.alt ?? ''} title={image.title} fill />
          </div>
        ) : (
          showImage && <CardNotAvailableImage />
        )}
        {hasGamePlatform && !hasSpinner && (
          <CardHoverMask label={labelGioca!} visible={isHover} handleLaunchGame={handleLaunchGame} />
        )}
        {hasSpinner && <CardSpinner />}
        {children}
      </div>
    );
  }
  return (
    <button type="button" className={styles.button} onClick={() => handleLaunchGame()}>
      <div
        className={`${styles.container} ${styles.linked} ${imageClass ? imageClass : ''}`}
        style={{
          backgroundColor: bgColor ?? 'transparent',
        }}
      >
        {image && haveImg && srcImage! ? (
          <div className={`${styles.imgContainer} ${isAuthenticated && styles.imgHasLinearGradient} `}>
            <Image className={styles.image} src={srcImage!} alt={image.alt ?? ''} title={image.title} fill />
          </div>
        ) : (
          showImage && <CardNotAvailableImage />
        )}
        {children}
      </div>
    </button>
  );
};
