import { Icon, IconsEnum } from 'components/Icons';
import { useTheme } from 'next-themes';
import styles from './SwitchTheme.module.scss';
export type SwitchThemeProps = {
  labelLight: string;
  labelDark: string;
};

export const SwitchTheme = ({ labelLight, labelDark }: SwitchThemeProps) => {
  const { theme, setTheme } = useTheme();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.currentTarget.checked) {
      setTheme('dark');
      e.currentTarget.setAttribute('aria-pressed', 'true');
    } else {
      setTheme('light');
      e.currentTarget.removeAttribute('aria-pressed');
    }
  };

  const isDarkMode = (theme: string | undefined): boolean => {
    return theme === 'dark';
  };

  return (
    <label className={styles.label} htmlFor="switch">
      <span className={styles.labelText}>{labelLight}</span>
      <input
        type="checkbox"
        name="switch"
        id="switch"
        className={styles.checkbox}
        onChange={(e) => handleChange(e)}
        checked={isDarkMode(theme)}
      />
      <p className={styles.slider}>
        <span className={styles.iconContainer}>
          <Icon className={styles.icon} iconId={theme === 'light' ? IconsEnum.LIGHT_MODE : IconsEnum.DARK_MODE} />
        </span>
      </p>
      <span className={styles.labelText}>{labelDark}</span>
    </label>
  );
};
