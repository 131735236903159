import useWindowSize from 'hooks/useWindowSize';
import { useCallback, useEffect, useState } from 'react';

// exp base 2 to allow bitwice check
export enum enComparer {
  // eslint-disable-next-line no-unused-vars
  less = 1,
  // eslint-disable-next-line no-unused-vars
  equal = 2,
  // eslint-disable-next-line no-unused-vars
  greater = 4,
}

// useScreenWidth(enComparer.equal, 500)                      =-> width === 500
// useScreenWidth(enComparer.less, 500)                       =-> width < 500
// useScreenWidth(enComparer.greater, 500)                    =-> width > 500
// useScreenWidth(enComparer.less | enComparer.equal, 500)    =-> width <= 500
// useScreenWidth(enComparer.greater | enComparer.equal, 500) =-> width >= 500
export const useScreenWidth = (comparer: enComparer, size: number): boolean => {
  const { width } = useWindowSize();

  const [value, setValue] = useState(false);

  const isComparer = (input: enComparer, check: enComparer) => (input & check) === check;

  const doCheck = useCallback(
    (comparer: enComparer, size: number): boolean => {
      if (!width) return false;

      return Object.values(enComparer)
        .filter((item) => isNaN(Number(item)))
        .some((k) => {
          switch (k) {
            case 'less': {
              return isComparer(comparer, enComparer.less) && width < size;
            }
            case 'equal': {
              return isComparer(comparer, enComparer.equal) && width === size;
            }
            case 'greater': {
              return isComparer(comparer, enComparer.greater) && width > size;
            }
          }
          return false;
        });
    },
    [width]
  );

  useEffect(() => {
    const nextValue = doCheck(comparer, size);

    if (nextValue !== value) {
      setValue(nextValue);
    }
  }, [comparer, doCheck, size, value]);

  return value;
};
