import { DynamicDialog } from 'components/dynamicDialog';
import { dialogInfoChange, selectDialogInfo } from 'features/modal/modalSlice';
import { useAppDispatch } from 'lib/centralStore';
import { useTypedSelector } from 'sites/snai/lib/store';

export const WelcomeModal = () => {
  const dispatch = useAppDispatch();
  const dialogInfo = useTypedSelector(selectDialogInfo);

  return (
    dialogInfo?.isOpen && (
      <DynamicDialog
        isMobileFullScreen
        dialogInfo={dialogInfo}
        onOpenChange={() => {
          dispatch(dialogInfoChange({ isOpen: false }));
          dialogInfo.closeAction && dialogInfo.closeAction();
        }}
      />
    )
  );
};
