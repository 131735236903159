import { useTypedSelector } from 'lib/centralStore';
import { useMemo } from 'react';
import {
  selectAllItemsFactory,
  selectFiltersSelectedFactory,
  selectFiltersSelectedLengthFactory,
  selectPageNumberFactory,
  selecttUpdatingItemsFactory,
} from './selectors';

export const useAllItems = () => {
  const selector = useMemo(selectAllItemsFactory, []);
  return useTypedSelector(selector);
};

export const usePageNumber = () => {
  const selector = useMemo(selectPageNumberFactory, []);
  return useTypedSelector(selector);
};

export const useFiltersSelected = () => {
  const selector = useMemo(selectFiltersSelectedFactory, []);
  return useTypedSelector(selector);
};

export const useFiltersSelectedLength = () => {
  const selector = useMemo(selectFiltersSelectedLengthFactory, []);
  return useTypedSelector(selector);
};

export const useIsItemUpdating = (id: string) => {
  const selector = useMemo(() => selecttUpdatingItemsFactory(id), [id]);
  return useTypedSelector(selector);
};
