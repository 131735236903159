import * as DialogRadix from '@radix-ui/react-dialog';
import styles from './Dialog.module.scss';
import { DialogContent } from './dialogContent/DialogContent';
import { DialogTrigger } from './dialogTrigger/DialogTrigger';

export type DialogProps = {
  triggerClassName?: string;
  triggerText?: string;
  children: React.ReactNode;
  contentClassName?: string;
  hasTrigger?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  dialogTitle?: string;
  isMobileFullScreen?: boolean;
  isClosable?: boolean;
  hasClickOutside?: boolean;
  onCloseClick?: () => void;
  hasHighlightedTitle?: boolean;
  classNameCloseBtn?: string;
};

export const Dialog = ({
  triggerClassName,
  triggerText,
  children,
  contentClassName,
  hasTrigger,
  open,
  onOpenChange,
  dialogTitle,
  isMobileFullScreen,
  isClosable = true,
  hasClickOutside,
  onCloseClick,
  hasHighlightedTitle,
  classNameCloseBtn,
}: DialogProps) => {
  return (
    <DialogRadix.Root open={open} onOpenChange={onOpenChange} modal={true}>
      {hasTrigger && (
        <DialogTrigger>
          <button type="button" className={`${styles.button} ${triggerClassName && triggerClassName}`}>
            {triggerText && triggerText}
          </button>
        </DialogTrigger>
      )}
      <DialogContent
        isMobileFullScreen={isMobileFullScreen}
        title={dialogTitle}
        className={contentClassName ? contentClassName : ''}
        isClosable={isClosable}
        onCloseClick={onCloseClick}
        hasClickOutside={hasClickOutside}
        hasHighlightedTitle={hasHighlightedTitle}
        classNameCloseBtn={classNameCloseBtn}
      >
        {children}
      </DialogContent>
    </DialogRadix.Root>
  );
};
