import { AnchorLink, AnchorLinkType } from 'components/anchorLink';
import { Icon, IconsEnum } from 'components/Icons';
import { MenuItemBlock, Navigation } from '../Header';
import React, { useCallback, useEffect, useState } from 'react';

import { BackgroundImage } from 'components/backgroundImage';
import { LinkProps } from 'next/link';
import classNames from 'classnames';
import { isTruthy } from 'utility/functions';
import styles from './AccordionMenu.module.scss';
import { useRouter } from 'next/router';
export type AccordionMenuProps = {
  data: (Navigation | LinkProps | AnchorLinkType)[];
  isDefault?: boolean;
  menuIsOpen: boolean;
  setIsDefault: Function;
  setMenuIsOpen: Function;
};

export const AccordionMenu: React.FC<AccordionMenuProps> = ({
  data,
  isDefault,
  menuIsOpen,
  setIsDefault,
  setMenuIsOpen,
}: AccordionMenuProps) => {
  const [activeItem, setActiveItem] = useState<number | null>(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleToggleAccordion = (index: number | null) => {
    if (activeItem === index) {
      setIsDefault(true);
      setIsExpanded((prevState) => !prevState);
      return setActiveItem(null);
    }
    setIsDefault(false);
    setActiveItem(index);
  };
  const router = useRouter();
  const handleMenuOpenOnChangeRouter = useCallback(() => {
    if (menuIsOpen) {
      setMenuIsOpen(false);
    }
  }, [menuIsOpen, setMenuIsOpen]);

  useEffect(() => {
    router.events.on('routeChangeStart', handleMenuOpenOnChangeRouter);
    return () => {
      router.events.off('routeChangeStart', handleMenuOpenOnChangeRouter);
    };
  }, [handleMenuOpenOnChangeRouter, router]);

  return (
    <div className={classNames(styles.accordionMenu, menuIsOpen ? styles.show : styles.hide)}>
      <BackgroundImage imageClassName={styles.logo} />
      {data?.map((element: Navigation & LinkProps, index) => {
        const { menuItemBlock } = element.navigationItemBlock?.[0] ?? {};
        return isTruthy(menuItemBlock?.length) ? (
          <h3 className={styles.itemContainer} key={element.id}>
            <button
              id={`${element.id}-header`}
              key={element.id}
              type="button"
              className={styles.btnAccordion}
              aria-label={element.label}
              data-state={isExpanded ? 'open' : 'closed'}
              aria-controls={`${element.id}-panel`}
              onClick={() => handleToggleAccordion(index)}
            >
              <span
                className={classNames(styles.item, activeItem === index ? styles.itemActive : styles.itemInActive, {
                  [styles.itemDefault]: isDefault,
                })}
              >
                {element.label}
              </span>
              <Icon
                color="var(--color-primary)"
                iconId={IconsEnum.CHEWRON_DOWN}
                className={`${styles.arrow} ${activeItem === index ? styles.arrowUp : styles.arrowDown}`}
              />
            </button>
            <section
              id={`${element.id}-panel`}
              aria-labelledby={`${element.id}-header`}
              hidden={!isExpanded}
              className={`${styles.content} ${activeItem === index ? styles.open : styles.close}`}
            >
              {menuItemBlock?.map(({ id, label, linkBlock }: MenuItemBlock) => (
                <AnchorLink key={id} href={`${linkBlock[0].href}`} className={styles.link}>
                  {label}
                </AnchorLink>
              ))}
            </section>
          </h3>
        ) : (
          <h3 key={element.id} className={styles.itemContainer}>
            <AnchorLink
              href={`${element.navigationItemBlock![0].href}`}
              className={classNames(styles.item, activeItem === index ? styles.itemActive : styles.itemInActive, {
                [styles.itemDefault]: isDefault,
              })}
            >
              {element.label}
            </AnchorLink>
          </h3>
        );
      })}
    </div>
  );
};
