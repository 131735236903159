import {
  selectEsitiKeyByAvvenimenti as selectIppicaEsitiKeyByAvvenimenti,
  selectNumEsitiIppica,
} from 'features/ippica/components/ippicaTicket/ippicaTicketSelectors';
import {
  selectEsitiKeyByAvvenimenti,
  selectNumEsitiSport,
} from 'features/sport/components/sportTicket/sportTicketSelectors';
import {
  selectNumEsitiVirtual,
  selectEsitiKeyByAvvenimenti as selectVirtualEsitiKeyByAvvenimenti,
} from 'features/virtual/components/virtualTicket/virtualTicketSelectors';
import { CarrelloScommesseCountList, CarrelloTipo, CarrelloTipoType } from './types';
import { isAvvenimentiIppica, isCarrelloTypeIppica, isCarrelloTypeSport, isCarrelloTypeVirtual } from './utils';

import { createSelector } from '@reduxjs/toolkit';
import { CostantiCalcoloIppicaTicket } from 'features/ippica/components/ippicaTicket/ippicaTicketSlice';
import { IppicaTicketInfoEsito } from 'features/ippica/components/ippicaTicket/types';
import { SportTicketEsito } from 'features/sport/components/sportTicket/utils/calcoloCarrello/types';
import { CostantiCalcoloSportTicket } from 'features/sport/components/sportTicket/utils/calcoloCarrello/utils';
import { VirtualTicketEsito } from 'features/virtual/components/virtualTicket/utils/calcoloCarrello/types';
import { CostantiCalcoloVirtualTicket } from 'features/virtual/components/virtualTicket/utils/calcoloCarrello/utils';
import { RootState } from 'lib/centralStore';
import { CarrelloState } from './carrelloSlice';

export const selectCarrello = (state: RootState): CarrelloState => state.carrello;

export const selectIsOpenCarrello = (state: RootState) => state.carrello.isOpen;

export const selectTab = createSelector(selectCarrello, (slice) => slice.tab);

export const selectTipo = createSelector(selectCarrello, (slice) => slice.tipo);
export const selectDefaultSistemaValue = createSelector(selectTipo, (tipo) => {
  switch (tipo) {
    case CarrelloTipo.Sport:
      return CostantiCalcoloSportTicket.DefaultPuntataPerSistema / 100;
    case CarrelloTipo.Virtual:
      return CostantiCalcoloVirtualTicket.DefaultPuntataPerSistema / 100;
    case CarrelloTipo.Ippica:
      return CostantiCalcoloIppicaTicket.DefaultPuntataPerSistema / 100;
  }
});

export const selectDefaultPuntata = createSelector(selectTipo, (tipo) => {
  switch (tipo) {
    case CarrelloTipo.Sport:
      return CostantiCalcoloSportTicket.DefaultPuntata / 100;
    case CarrelloTipo.Virtual:
      return CostantiCalcoloVirtualTicket.DefaultPuntata / 100;
    case CarrelloTipo.Ippica:
      return CostantiCalcoloIppicaTicket.DefaultPuntata;
  }
});

export const selectVariationByTipo = createSelector(selectTipo, (tipo) => {
  switch (tipo) {
    case CarrelloTipo.Sport:
      return CostantiCalcoloSportTicket.VariationPuntata / 100;
    case CarrelloTipo.Virtual:
      return CostantiCalcoloVirtualTicket.VariationPuntata / 100;
    case CarrelloTipo.Ippica:
      return CostantiCalcoloIppicaTicket.VariationPuntata / 100;
  }
});

export const selectMinValueByTipo = createSelector(selectTipo, (tipo) => {
  switch (tipo) {
    case CarrelloTipo.Sport:
      return CostantiCalcoloSportTicket.VariationPuntata / 100;
    case CarrelloTipo.Virtual:
      return CostantiCalcoloVirtualTicket.VariationPuntata / 100;
    case CarrelloTipo.Ippica:
      return CostantiCalcoloIppicaTicket.MinPuntataSistema / 100;
  }
});

export const selectErrorLabels = createSelector([selectCarrello], (carrello) => carrello.errorsLabels);

export const selectIsFullCart = createSelector([selectCarrello], (carrello) => carrello.isFullCart);

export const selectOpenedBet = createSelector(selectCarrello, (slice) => slice.openedBet);

export const selectIsLoadingVendita = createSelector(selectCarrello, (slice): boolean => slice.isLoadingVendita);

export const selectSliceTicketByType = (
  { virtualTicket, ippicaTicket, sportTicket }: RootState,
  type: CarrelloTipoType
) => {
  switch (type) {
    case CarrelloTipo.Virtual:
      return virtualTicket;
    case CarrelloTipo.Ippica:
      return ippicaTicket;
    case CarrelloTipo.Sport:
      return sportTicket;
  }

  throw new Error(`"${type}" is not a valid option for CarrelloTipo`);
};

export const selectTicketByType = createSelector([selectSliceTicketByType], (slice) => slice.ticket);

export const selectAvvenimentiByTicket = createSelector([selectTicketByType], (ticket) => {
  return ticket?.avvenimenti;
});

export const selectDescrizioneLastAvvenimentoAddedByTicket = createSelector(
  [selectAvvenimentiByTicket],
  (avvenimenti) => {
    const avvenimentoLastAddedIndex = avvenimenti ? avvenimenti.length - 1 : null;
    return avvenimenti && avvenimentoLastAddedIndex !== null
      ? isAvvenimentiIppica(avvenimenti)
        ? avvenimenti[avvenimentoLastAddedIndex]?.descrizioneAvvenimento
        : avvenimenti[avvenimentoLastAddedIndex]?.descrizione
      : null;
  }
);
export const selectIsSistemaByTicket = createSelector([selectTicketByType], (ticket) => ticket?.sistema);
export const selectAvvenimentoLastAddedIndex = createSelector([selectAvvenimentiByTicket], (avvenimenti) => {
  return avvenimenti ? avvenimenti.length - 1 : null;
});

export const selectSistemiDaGiocareByType = createSelector(
  [selectSliceTicketByType],
  (slice) => slice.sistemiDaGiocare
);

export const selectPuntataPerScommessaByType = createSelector(
  [selectSliceTicketByType],
  (slice) => slice.puntataPerScommessa
);

export const selectLastScommessa = createSelector(
  [
    (state: RootState, type: CarrelloTipoType) => selectAvvenimentiByTicket(state, type),
    (_state: RootState, type: CarrelloTipoType) => type,
  ],
  (avvenimenti, type) => {
    if (avvenimenti) {
      const avvenimentoLastAdded = (avvenimenti ?? []).at(-1);
      const esitoLastAdded = ((avvenimentoLastAdded ?? {})?.esiti ?? []).at(-1);

      if (isCarrelloTypeSport(type)) {
        const esito = esitoLastAdded as SportTicketEsito;
        return {
          tipoScommessaTrKey: esito?.descrizioneTipoScommessaWithInfoAggTrKey,
          tipoScommessa: esito?.descrizioneTipoScommessaWithInfoAgg,
          esito: esito?.descrizioneEsito,
          quota: (esito?.quota ?? 0) / 100,
        };
      }
      if (isCarrelloTypeVirtual(type)) {
        const esito = esitoLastAdded as VirtualTicketEsito;
        return {
          tipoScommessa: esito?.descrizioneTipoScommessa,
          esito: esito?.descrizione,
          quota: (esito?.quota ?? 0) / 100,
        };
      }
      if (isCarrelloTypeIppica(type)) {
        const esito = esitoLastAdded as IppicaTicketInfoEsito;
        return {
          tipoScommessa: esito?.descrizioneTipoScommessa,
          esito: esito?.nomeCavallo,
          quota: (esito?.quota ?? 0) / 100,
        };
      }
    }
    return null;
  }
);

export const selectNumEsitiByTicketType = createSelector(selectTicketByType, (ticket) => ticket?.numEsiti ?? 0);

export const selectIsOpenCombinazioniByType = createSelector(
  [selectSliceTicketByType],
  (slice) => slice.isOpenCombinazioni
);
export const selectEsitiByTicket = createSelector(selectSliceTicketByType, (state) => state.esiti);
export const selectErroriByTicket = createSelector(selectSliceTicketByType, (state) => state.errori);
export const selectPuntataSingolaMultiplaByTicketType = createSelector(
  selectSliceTicketByType,
  (ticket) => ticket.puntataSingolaMultipla
);

export const selectErroriCarrelloByTicket = createSelector(
  (state: RootState, type: CarrelloTipo) => selectErroriByTicket(state, type),
  (state) => state.erroriCarrello
);

export const selectErroriInserimentoByTicket = createSelector(selectErroriByTicket, (state) => state.erroreInserimento);

export const selectCarrelloScommesseCount = createSelector(
  [selectEsitiKeyByAvvenimenti, selectVirtualEsitiKeyByAvvenimenti, selectIppicaEsitiKeyByAvvenimenti],
  (sportEsiti, virtualEsiti, ippicaEsiti) => {
    {
      let carrelloScommesseCount: CarrelloScommesseCountList = [];
      carrelloScommesseCount.push({
        label: CarrelloTipo.Sport,
        count: sportEsiti?.length,
      });
      carrelloScommesseCount.push({
        label: CarrelloTipo.Virtual,
        count: virtualEsiti?.length ?? 0,
      });
      carrelloScommesseCount.push({
        label: CarrelloTipo.Ippica,
        count: ippicaEsiti.length ?? 0,
      });

      return carrelloScommesseCount;
    }
  }
);

export const selectLastTicketByTicket = createSelector(selectSliceTicketByType, (slice) => slice.lastTicket);

export const selectVenditaAlertSuccess = (state: RootState): boolean => state.carrello.venditaAlertSuccess;

export const selectVenditaAlertFailed = (state: RootState) => state.carrello.venditaAlertFailed;

export const selectNumEsitiEveryTicket = createSelector(
  [selectNumEsitiVirtual, selectNumEsitiSport, selectNumEsitiIppica],
  (numEsitiVirtual, numEsitiSport, numEsitiIppica) => {
    const numEsitiVirtualTemp = numEsitiVirtual ?? 0;
    const numEsitiSportTemp = numEsitiSport ?? 0;
    const numEsitiIppicaTemp = numEsitiIppica ?? 0;
    return numEsitiVirtualTemp + numEsitiSportTemp + numEsitiIppicaTemp;
  }
);

export const selectTotalePuntataScommesseSistemaByTicket = createSelector(
  [selectPuntataPerScommessaByType],
  (puntataPerScommessa) => {
    let totale = 0;
    for (let id in puntataPerScommessa) {
      if (puntataPerScommessa.hasOwnProperty(id)) {
        totale += puntataPerScommessa[id];
      }
    }
    return totale;
  }
);
