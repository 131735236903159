import { MouseEventHandler, useMemo } from 'react';
import { Icon, IconsEnum } from '../../Icons';

export type HeaderHamburgerButtonProps = {
  iconContaienrClass: string;
  menuIsOpen: boolean;
  handleMenuOpen: MouseEventHandler<HTMLButtonElement>;
  iconClass: string;
};

const objClosed = <Icon iconId={IconsEnum.CLOSE} color="var(--color-on-bg-primary)" />;
const objOpened = <Icon iconId={IconsEnum.MENU} color="var(--color-on-bg-primary)" />;

export const HeaderHamburgerButton = ({
  iconClass,
  menuIsOpen,
  iconContaienrClass,
  handleMenuOpen,
}: HeaderHamburgerButtonProps) => {
  const itemMenu = useMemo(() => {
    const wIcon = menuIsOpen ? objClosed : objOpened;
    return { ...wIcon, props: { ...wIcon.props, className: iconClass } };
  }, [iconClass, menuIsOpen]);

  return (
    <button
      type="button"
      className={iconContaienrClass}
      aria-label={menuIsOpen ? IconsEnum.CLOSE : IconsEnum.MENU}
      onClick={handleMenuOpen}
    >
      {itemMenu}
    </button>
  );
};
