export type { NeedHelpProps as NeedHelpType } from './NeedHelp';

import { NeedHelpProps } from './NeedHelp';
import { useLazyLoad } from 'hooks';

export const NeedHelp = (props: NeedHelpProps) => {
  return useLazyLoad({
    doLoad: () => import('components/needHelp/NeedHelp').then((mod) => mod.NeedHelp),
    height: 72,
    props,
  });
};
