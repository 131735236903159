import { useCallback, useEffect, useRef, useState } from 'react';

import { isClientSide } from 'utility/functions';

/* eslint-disable no-unused-vars */
export enum ScrollDirection {
  UP = 'up',
  DOWN = 'down',
}
/* eslint-enable no-unused-vars */

export const useScrollDirection = () => {
  const lastScrollPosition = useRef(0);
  const [scrollDir, setScrollDir] = useState<ScrollDirection | undefined>(undefined);
  const [isTop, setIsTop] = useState<boolean>(true);

  const onScrollHandler = useCallback(() => {
    if (!isClientSide()) return;

    const currentScrollTop = window.scrollY || document.documentElement.scrollTop;
    const nextIsTop = currentScrollTop === 0;
    const nextScrollDir =
      currentScrollTop > lastScrollPosition.current && currentScrollTop > 70
        ? ScrollDirection.DOWN
        : ScrollDirection.UP;

    if (nextIsTop !== isTop) {
      setIsTop(nextIsTop);
    }
    if (nextScrollDir !== scrollDir) {
      setScrollDir(nextScrollDir);
    }
    lastScrollPosition.current = currentScrollTop;
  }, [isTop, scrollDir]);

  useEffect(() => {
    if (isClientSide()) {
      window.addEventListener('scroll', onScrollHandler, { passive: true });
    }
    return () => {
      if (isClientSide()) {
        window.removeEventListener('scroll', onScrollHandler);
      }
    };
  }, [onScrollHandler]);

  return { scrollDir, isTop };
};
