import styles from './SlotCardDescription.module.scss';

export type SlotCardDescriptionProps = {
  functionality: string[];
  volatilityLabel: string;
  volatilityValue: string;
};

export const SlotCardDescription = ({ functionality, volatilityLabel, volatilityValue }: SlotCardDescriptionProps) => {
  return (
    <p className={styles.container}>
      {functionality.map((func: string, _, self: string[]) => {
        return func + (self.length > 1 ? ', ' : ' ');
      })}
      | {volatilityLabel} {volatilityValue}
    </p>
  );
};
