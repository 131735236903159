import { AnchorLink, AnchorLinkType } from '../anchorLink';
import { Icon, toIconsEnum } from 'components/Icons';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { ScrollDirection, useScrollDirection } from 'hooks/useScrollDirection';

import { AccordionMenu } from './accordionMenu';
import { DatoCmsImageTheme } from 'lib/datoCms/types';
import { HeaderHamburgerButton } from './headerHambugerButton';
import { HeaderNavigation } from './headerNavigation';
import { ThemeLogo } from 'components/themeLogo';
import type { ThemeLogoType } from 'components/themeLogo';
import { UserNavigation } from './userNavigation';
import classNames from 'classnames';
import { isTruthy } from 'utility/functions';
import { setIsOpenLoginModalValue } from '../../features/modal/modalSlice';
import styles from './Header.module.scss';
import { useAppDispatch } from 'lib/centralStore';
import { useBlockScroll } from 'hooks/useBlockScroll';
import { useGetLabelByKey } from 'hooks/useLingUI';
import { useInternalSession } from 'hooks/useInternalSession';
import useOutsideAlerter from 'hooks/useClickOutside';
import { useRouter } from 'next/router';

export type HeaderProps = {
  onlyLogo?: boolean;
  promoHref?: string;
  logoBrand?: ThemeLogoType;
  logoSnaiPi: DatoCmsImageTheme;
  profileHref?: string;
  navigationList?: (Navigation | AnchorLinkType)[];
  snaiMessageHref?: string;
  userNavigationList?: Navigation[];
  mobileUserNavigationList?: Navigation[];
};

type NavigationItemBlock1 = DetailMenu & AnchorLinkType;

export type Navigation = {
  id: string;
  label: string;
  icon?: { iconName: string }[];
  navigationItemBlock?: NavigationItemBlock1[];
};
export type DetailMenu = {
  id: string;
  menuItemBlock?: MenuItemBlock[];
};
export type MenuItemBlock = {
  linkBlock: AnchorLinkType[];
  label: string;
  id: string;
};

export const Header = ({
  onlyLogo,
  promoHref,
  logoBrand,
  logoSnaiPi,
  profileHref,
  navigationList,
  snaiMessageHref,
  userNavigationList,
  mobileUserNavigationList,
}: HeaderProps) => {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const [id, setId] = useState<number | null>(null);
  const [isDefault, setIsDefault] = useState<boolean>(true);
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [indexItem, setIndexItem] = useState<number>(0);
  const headerRef = useRef<HTMLElement>(null);
  const labelAccedi = useGetLabelByKey('login')!;
  const dispatch = useAppDispatch();

  const { isReady } = useRouter();

  const { isAuthenticated, status } = useInternalSession();
  const allNavigationList = [, ...(navigationList ?? []), ...(userNavigationList ?? [])].filter((x) => !!x) as Array<
    Navigation | AnchorLinkType
  >;

  useBlockScroll(menuIsOpen);
  const { scrollDir, isTop } = useScrollDirection();

  const handleShowDetailsMenu = useCallback(
    // (itemId: string | null, array: Navigation[]) => {
    (itemId: number, array: Navigation[]) => {
      const searchObject = array.find((item) => +item.id === +itemId);
      searchObject && setIndexItem(+searchObject?.id);
      setId((prevState) => {
        if (prevState && prevState === itemId) {
          setShowDetail(false);
          return null;
        } else {
          setShowDetail(true);
          return itemId;
        }
      });
    },
    [setIndexItem, setId, setShowDetail]
  );

  useOutsideAlerter({
    ref: headerRef,
    onClickOutside: () => {
      setShowDetail(false);
      setId(null);
    },
  });

  const handleMenuOpen = (): void => {
    setMenuIsOpen((prevState) => !prevState);
  };

  const objLogo = useMemo(() => {
    const { alt, title, src, srcDarkMode, redirectUrlLogo } = logoBrand ?? {};

    return (
      <AnchorLink href={redirectUrlLogo ?? '/'} className={styles.logoContainer}>
        {src && <ThemeLogo alt={alt ?? ''} src={src} title={title} priority srcDarkMode={srcDarkMode} fill />}
      </AnchorLink>
    );
  }, [logoBrand]);

  if (isTruthy(onlyLogo)) {
    return (
      <header
        ref={headerRef}
        className={classNames(styles.container, {
          [styles.scrolledUp]: scrollDir === ScrollDirection.UP && !isTop,
          [styles.scrolledDown]: scrollDir === ScrollDirection.DOWN && !isTop,
        })}
      >
        <section className={styles.headerOnlyLogo}>
          <div className={styles.wrapperOnlyLogo}>{objLogo}</div>
        </section>
      </header>
    );
  }

  return (
    <header
      ref={headerRef}
      className={classNames(styles.container, {
        [styles.scrolledUp]: scrollDir === ScrollDirection.UP && !isTop,
        [styles.scrolledDown]: scrollDir === ScrollDirection.DOWN && !isTop,
      })}
    >
      <section className={styles.header}>
        <div className={styles.wrapper}>
          <div className={styles.firstCol}>
            <HeaderHamburgerButton
              menuIsOpen={menuIsOpen}
              iconContaienrClass={styles.menuIconContainer}
              iconClass={styles.menuIcon}
              handleMenuOpen={handleMenuOpen}
            />
            {objLogo}
            <HeaderNavigation
              id={id}
              indexItem={indexItem}
              showDetail={showDetail}
              navigation={navigationList}
              allNavigationList={allNavigationList}
              handleShowDetailsMenu={handleShowDetailsMenu}
            />
          </div>

          {!isReady || status === 'loading' ? (
            <div className={styles.buttonsListContainer} />
          ) : (
            <div className={styles.buttonsListContainer}>
              {isAuthenticated ? (
                <UserNavigation
                  indexItem={indexItem}
                  promoHref={promoHref}
                  showDetail={showDetail}
                  logoSnaiPi={logoSnaiPi}
                  headerHeight={headerRef?.current?.getBoundingClientRect().height}
                  allNavigationList={allNavigationList}
                  userNavigationList={userNavigationList ?? []}
                  mobileUserNavigationList={mobileUserNavigationList ?? []}
                  handleShowDetailsMenu={handleShowDetailsMenu}
                />
              ) : (
                <React.Fragment>
                  {userNavigationList?.map((el) => {
                    return (
                      el.label === 'promo' && (
                        <AnchorLink key={el.id} href={promoHref} className={styles.btnLink}>
                          <Icon
                            iconId={toIconsEnum(el.icon![0].iconName)}
                            className={styles.icon}
                            color="var(--color-primary)"
                          />
                        </AnchorLink>
                      )
                    );
                  })}
                  <button
                    type="button"
                    className={styles.btnLogin}
                    suppressHydrationWarning={true}
                    onClick={() => dispatch(setIsOpenLoginModalValue(true))}
                  >
                    {labelAccedi}
                  </button>
                </React.Fragment>
              )}
            </div>
          )}
        </div>
      </section>
      <AccordionMenu
        data={navigationList ?? []}
        isDefault={isDefault}
        setIsDefault={setIsDefault}
        menuIsOpen={menuIsOpen}
        setMenuIsOpen={setMenuIsOpen}
      />
    </header>
  );
};
