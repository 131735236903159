import { CardTitle } from '../../cardTitle';
import styles from './CardTextContainer.module.scss';

export type CardTextContainerProps = {
  children?: React.ReactNode;
  title: string;
  url?: string;
};

export const CardTextContainer = ({ children, title, url }: CardTextContainerProps) => {
  return (
    <div className={styles.container}>
      <CardTitle title={title} url={url && url} />
      <div className={styles.description}>{children}</div>
    </div>
  );
};
