import { AnyAction } from '@reduxjs/toolkit';
import { LazyGetTriggerType, SlotToggleFavourite } from 'features/slotArchive/actions';
import { useIsItemUpdating } from 'features/slotArchive/slotArchiveHooks';
import { useDispatch } from 'react-redux';
import { Icon, IconsEnum } from '../../../Icons';
import styles from './CardFavouriteBtn.module.scss';

export type CardFavouriteBtnProps = {
  idGame: string;
  verticale: string;
  favourite: boolean;
  triggerRefetch?: LazyGetTriggerType;
  className?: string;
  colorIcon?: string;
  classNameIconContainer?: string;
  classIcon?: string;
};

export const CardFavouriteBtn = ({
  idGame,
  verticale,
  favourite,
  triggerRefetch,
  className,
  colorIcon,
  classIcon,
  classNameIconContainer,
}: CardFavouriteBtnProps) => {
  const dispatch = useDispatch(); //useAppDispatch();
  const disabled = useIsItemUpdating(idGame);

  const handleFavouriteClick = async () => {
    const isFavorite = !!favourite;
    dispatch(SlotToggleFavourite({ id: idGame, verticale, isFavorite, triggerRefetch }) as unknown as AnyAction);
  };
  return (
    <button
      type="button"
      disabled={disabled}
      className={`${className ? className : styles.button}`}
      onClickCapture={handleFavouriteClick}
    >
      <span className={`${classNameIconContainer ? classNameIconContainer : styles.iconContainer}`}>
        <Icon
          iconId={favourite ? IconsEnum.PREFERRED_ON : IconsEnum.PREFERRED_OFF}
          className={`${styles.icon} ${classIcon ? classIcon : ''}`}
          color={favourite ? 'var(--color-primary)' : 'var(--color-neutral-01)'}
        />
        {!favourite && (
          <Icon
            iconId={IconsEnum.PREFERRED_OFF_BCK}
            className={`${styles.icon}  ${classIcon ? classIcon : ''}`}
            color={colorIcon ? colorIcon : 'var(--color-neutral-02)'}
          />
        )}
      </span>
    </button>
  );
};
