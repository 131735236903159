import styles from './Card.module.scss';

export type CardProps = {
  id?: string;
  children?: React.ReactNode;
  containerClass?: string;
};

export const Card: React.FC<CardProps> = ({ containerClass, children }: CardProps) => {
  return <article className={`${styles.container} ${containerClass ? containerClass : ''}`}>{children}</article>;
};
