import { setIsOpenLoginModalValue } from 'features/modal/modalSlice';
import { store } from 'sites/snai/lib/store';
import { UsersGetProfileResponse } from 'types/swagger';

export const callbackOnAuthenticated = <T>(isAuthenticated: boolean, callback: () => T) => {
  isAuthenticated ? callback() : store.dispatch(setIsOpenLoginModalValue(true));
};

export const editUrlWithSessionParam = (url: string, profile: UsersGetProfileResponse) => {
  if (!url.includes('?')) return url;

  const urlParams = new URLSearchParams(url);
  for (const [key, value] of urlParams.entries()) {
    if (value.includes('__')) {
      const decryptedValue = value.replaceAll('__', '');
      urlParams.set(key, profile[decryptedValue]);
    }
  }
  return decodeURIComponent(urlParams.toString());
};
