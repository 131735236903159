import { useAppDispatch, useTypedSelector } from 'lib/centralStore';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import {
  selectIsOpenLoginModal,
  selectIsOpenNewsDialog,
  setIsOpenLoginModalValue,
} from '../../features/modal/modalSlice';
import {
  DatoCmsCertificationsBlock,
  DatoCmsHeader,
  DatoCmsImage,
  DatoCmsImageTheme,
  DatoCmsMenuItemBlock,
  DatoCmsMightLike,
  DatoCmsNavigation,
  DatoCmsNeedHelp,
} from '../../lib/datoCms/types';
import { convertInImgType, convertInThemeLogoType } from '../../lib/datoCms/utils';
import { MenuItem, NavigationBlock } from '../footer/footerNavigation/FooterNavigation';
import { InstantPanel, InstantPanelProps } from '../instantPanel/InstantPanel';

import { BackgroundImage } from 'components/backgroundImage';
import { DynamicNewsDialog } from 'components/newsDialog/NewsDialog';
import { useNewsDialogHooks } from 'components/newsDialog/useNewsDialogHooks';
import { SeamlessLoginComponent } from 'components/seamlessLoginComponent';
import { SnaiPlusFrame } from 'components/snaiPlusFrame';
import { WelcomeModal } from 'components/welcomeModal';

import { selectIsLoadingVendita } from 'features/carrello/carrelloSelectors';
import { selectModalTutorial } from 'features/configuration/selectors';
import { setAlertMessage } from 'features/dashboard/dashboardSlice';
import { selectStringAutoesclusion } from 'features/dashboard/selectors';
import { useIsSeamlessMode } from 'hooks';
import useAgentDetect from 'hooks/useAgentDetect';
import { useInternalSession } from 'hooks/useInternalSession';
import Persist, { StorageKind } from 'lib/persist';
import Head from 'next/head';
import { ImageProps } from 'next/image';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { FROM_REGISTRATION, WINDOW_CLOSED } from 'utility/constant';
import { isClientSide, isTruthy } from 'utility/functions';
import { MightLikeContext } from '../../context/Mightlikes';
import { Footer } from '../footer';
import { CertificationBlock } from '../footer/footerDisclaimer/FooterDisclaimer';
import { Header } from '../header';
import { LoginModal } from '../modals/loginModal';
import { NeedHelp } from '../needHelp';
import styles from './Layout.module.scss';
import { InactiveMessage } from 'components/wrapperInactiveLogout';
import classNames from 'classnames';

export type LayoutProps = {
  header?: DatoCmsHeader;
  footer?: any;
  needHelp?: DatoCmsNeedHelp;
  mightLikes?: DatoCmsMightLike[];
  children?: ReactNode;
  instantPanel?: InstantPanelProps;
  headerOnlyLogo?: boolean;
  fullWidth?: boolean;
};

export const Layout = ({
  header,
  footer,
  children,
  needHelp,
  mightLikes,
  instantPanel,
  headerOnlyLogo,
  fullWidth,
}: LayoutProps) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { session, status, isAuthenticated } = useInternalSession();
  const seamlessMode = useIsSeamlessMode();
  const modalTutorial = useTypedSelector(selectModalTutorial);
  const isLoadingVendita = useTypedSelector(selectIsLoadingVendita);
  const isOpenNewsDialog = useTypedSelector(selectIsOpenNewsDialog);
  const isOpenLoginModal = useSelector(selectIsOpenLoginModal);
  const stringAutoesclusion = useTypedSelector(selectStringAutoesclusion);

  const { isSafari, isIos, isIPhone, isMac } = useAgentDetect();
  const { isVisible, setHidden } = useNewsDialogHooks();
  const sessionStorage = Persist(StorageKind.session);
  const cookie = useMemo(() => Persist(StorageKind.cookie), []);

  const contentViewPort = useMemo(() => {
    if (isIPhone) return 'width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, viewport-fit=cover';
    return 'initial-scale=1.0, width=device-width, minimum-scale=1';
  }, [isIPhone]);

  const isSeamlessWithCookieSetDisabled = useMemo(() => {
    return router.asPath.includes('/seamless-login/');
  }, [router]);

  useEffect(() => {
    if (isClientSide()) {
      if (isIos && isSafari) {
        document.body.classList.add('bodyOs');
      } else if (!isMac) {
        document.body.classList.add('bodyNotOsDesktop');
      } else if (isMac) {
        document.body.classList.add('bodyOsDesktop');
      } else {
        document.body.classList.remove('bodyOs');
        document.body.classList.remove('bodyNotOsDesktop');
        document.body.classList.remove('bodyOsDesktop');
      }
    }
  }, [isIos, isSafari]);

  useEffect(() => {
    if (stringAutoesclusion) {
      dispatch(setIsOpenLoginModalValue(true));
    }
  }, [stringAutoesclusion, dispatch]);

  const setCookieClosedWindow = useCallback(() => {
    if (isAuthenticated) {
      cookie.setItem(WINDOW_CLOSED, 'true');
    }
    dispatch(setAlertMessage(undefined));
  }, [cookie, isAuthenticated, dispatch]);

  useEffect(() => {
    window.addEventListener('beforeunload', setCookieClosedWindow);

    return () => {
      window.removeEventListener('beforeunload', setCookieClosedWindow);
    };
  }, [setCookieClosedWindow]);

  const renderFooter = () => {
    if (seamlessMode || !footer) {
      return null;
    }

    const {
      navigation,
      ippicaLogoList,
      paymentLogoList,
      certificationsBlock,
      settingsTitle,
      settingsLabelTema,
      switchTheme,
      ippicaTitle,
      disclaimer,
      logoAdm18,
      avvisoAdm,
    } = footer;

    const navigationRenamed = navigation.map((item: DatoCmsNavigation): NavigationBlock => {
      const menuList: MenuItem[] = item.menu[0].menuItemBlock.map((itemMenu: DatoCmsMenuItemBlock): MenuItem => {
        const { id, label, linkBlock: links } = itemMenu;
        const menuItem: MenuItem = { id, label, links };
        return menuItem;
      });
      return {
        id: item.id,
        title: item.title,
        menuList: menuList,
      };
    });

    const ippicaLogoListRenamed = ippicaLogoList.map((item: DatoCmsImage): ImageProps => {
      return convertInImgType(item);
    });

    const paymentLogoListRenamed = paymentLogoList.map((paymentLogo: DatoCmsImageTheme) => {
      return convertInThemeLogoType(paymentLogo);
    });

    const certificationsBlockRenamed = certificationsBlock.map(
      (item: DatoCmsCertificationsBlock): CertificationBlock => {
        const logos: DatoCmsImageTheme[] = item.logo;
        const logoThemeList = logos.map((logo: DatoCmsImageTheme) => convertInThemeLogoType(logo));
        return {
          id: item.id,
          title: item.title,
          logoList: logoThemeList,
        } as CertificationBlock;
      }
    );

    return (
      <Footer
        settingsTitle={settingsTitle}
        settingsLabelTema={settingsLabelTema}
        switchTheme={switchTheme[0]}
        ippicaTitle={ippicaTitle}
        ippicaLogoList={ippicaLogoListRenamed}
        paymentLogoList={paymentLogoListRenamed}
        footerNavigation={navigationRenamed}
        disclaimerParagraph={disclaimer}
        warningLogo={logoAdm18.url}
        warningText={avvisoAdm}
        certificationsBlock={certificationsBlockRenamed}
      />
    );
  };

  return (
    <MightLikeContext.Provider value={mightLikes!}>
      <div className={isLoadingVendita ? styles.isLoadingVendita : ''}>
        <Head>
          <meta name="viewport" content={contentViewPort} />
        </Head>

        {header && !seamlessMode && (
          <Header
            logoSnaiPi={header.logoSnaiPi[0]}
            logoBrand={convertInThemeLogoType(header.logoBrand[0])}
            navigationList={header.navigationList}
            promoHref={header.promoHref}
            profileHref={header.profileHref}
            snaiMessageHref={header.snaiMessageHref}
            userNavigationList={header.userNavigationList}
            mobileUserNavigationList={header.mobileUserNavigationList}
            onlyLogo={headerOnlyLogo}
          ></Header>
        )}
        <WelcomeModal />
        <InactiveMessage />
        <SeamlessLoginComponent />

        {/* TODO capire se vogliamo vada oltre al colonnaggio o no */}
        <BackgroundImage containerClassName={styles.containerLogo} imageClassName={styles.logo} />

        <main className={classNames(styles.container, { [styles.fullWidth]: fullWidth })}>
          {modalTutorial && isOpenNewsDialog && isVisible && !isSeamlessWithCookieSetDisabled && !seamlessMode && (
            <DynamicNewsDialog onHideModal={(value: boolean) => setHidden(value)} {...modalTutorial} />
          )}
          {children}
          {instantPanel && (
            <InstantPanel
              instantRoulette={instantPanel.instantRoulette}
              instantSlots={instantPanel.instantSlots}
              image={instantPanel.image}
            />
          )}
          {needHelp && !seamlessMode && (
            <div className={styles.container}>
              <NeedHelp heading={needHelp.title} optionList={needHelp.option} />
            </div>
          )}
        </main>
        {renderFooter()}
        {header && (
          <LoginModal
            logoBrand={convertInThemeLogoType(header.logoBrand[0])}
            isOpen={isOpenLoginModal}
            handleClose={() => {
              dispatch(setIsOpenLoginModalValue(false));
              if (isTruthy(sessionStorage.getItem(FROM_REGISTRATION))) sessionStorage.removeItem(FROM_REGISTRATION);
            }}
          />
        )}
      </div>
      <SnaiPlusFrame />
    </MightLikeContext.Provider>
  );
};
