import { Spinner } from 'components/spinner';
import styles from './CardSpinner.module.scss';

export type CardSpinnerProps = {};

export const CardSpinner = ({}: CardSpinnerProps) => {
  return (
    <div className={`${styles.container}`}>
      <Spinner className={styles.spinner} />
    </div>
  );
};
