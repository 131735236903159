import styles from './CardJackpotLabel.module.scss';

export type CardJackpotLabelProps = {
  label?: string;
  minJackpot?: string;
  maxJackpot?: string;
  className?: string;
};

export const CardJackpotLabel = ({ label, minJackpot, maxJackpot, className }: CardJackpotLabelProps) => {
  return (
    <p className={`${styles.container} ${className ? className : ''}`}>
      {label}
      <strong>
        {minJackpot && minJackpot + ' -'} {maxJackpot}
      </strong>
    </p>
  );
};
