import styles from './CardNewChip.module.scss';

export type CardNewChipProps = {
  label: string;
  hidden?: boolean;
};

export const CardNewChip = ({ label, hidden }: CardNewChipProps) => {
  return <div className={`${styles.container} ${hidden ? styles.hide : ''}`}>{label}</div>;
};
