import Persist, { StorageKind } from 'lib/persist';
import { useEffect, useRef } from 'react';
import { STORAGE_PREFIX_KEY } from 'utility/constant';
import { isTruthy } from 'utility/functions';

const DIALOG_PERSIST_KEY = `${STORAGE_PREFIX_KEY}_news_dialog`;

const newsDialogPersist = Persist(StorageKind.local);

const isNewsDialogVisible = () => {
  const value = newsDialogPersist.getItem(DIALOG_PERSIST_KEY);
  return !isTruthy(value);
};
const setNewsDialogHidden = (value) => newsDialogPersist.setItem(DIALOG_PERSIST_KEY, `${value}`);

export interface NewsDialogHookType {
  isVisible: boolean;
  setHidden: (value: boolean) => void;
}
export const useNewsDialogHooks = (): NewsDialogHookType => {
  const isVisibleRef = useRef<boolean>(false);
  useEffect(() => {
    isVisibleRef.current = isNewsDialogVisible();
  }, []);

  const setHidden = (value: boolean) => {
    isVisibleRef.current = value;
    setNewsDialogHidden(value);
  };

  return {
    isVisible: isVisibleRef.current,
    setHidden,
  };
};
