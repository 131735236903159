import styles from './CardHoverMask.module.scss';

export type CardHoverMaskProps = {
  label: string;
  visible: boolean;
  handleLaunchGame: () => void;
};

export const CardHoverMask = ({ label, visible, handleLaunchGame }: CardHoverMaskProps) => {
  return (
    <div className={`${styles.container} ${visible ? styles.visible : ''}`}>
      <button onClick={handleLaunchGame} className={styles.link}>
        {label}
      </button>
    </div>
  );
};
