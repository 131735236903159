import styles from './CardImageHeader.module.scss';

export type CardImageHeaderProps = {
  children: React.ReactNode;
  isStickToTop?: boolean;
};

export const CardImageHeader = ({ children, isStickToTop }: CardImageHeaderProps) => {
  return <div className={`${styles.container} ${isStickToTop && styles.noPadding}`}>{children}</div>;
};
