import { RootState } from 'lib/centralStore';
import { useSelector } from 'react-redux';

/**
 * @deprecated in favore di useInternalSession
 *
 * */
export function useIsAuthenticated(): boolean {
  const { isLogged } = useSelector((state: RootState) => state.auth);
  return isLogged;
}
