import { Icon, IconsEnum } from 'components/Icons';
import { Dialog } from 'components/dialog';
import { DialogInfo } from 'features/modal/modalSlice';
import { useIsAuthenticated } from 'hooks/useIsAuthenticated';
import useWindowSize from 'hooks/useWindowSize';
import { breakpoint } from 'utility/constant';
import styles from './DynamicDialog.module.scss';

export type DynamicDialogProps = {
  isMobileFullScreen: boolean;
  dialogInfo: DialogInfo;
  onOpenChange?: (open: boolean) => void;
  children?: React.ReactNode | React.ReactNode[];
  classNameContainerButtons?: string;
};

export const DynamicDialog = ({
  isMobileFullScreen,
  dialogInfo,
  onOpenChange,
  children,
  classNameContainerButtons,
}: DynamicDialogProps) => {
  const { width } = useWindowSize();
  const isMobile = width! < breakpoint.md;
  const isAuthenticated = useIsAuthenticated();
  const typeIconDictionary: {
    [key in 'info' | 'question' | 'autoesclusion-24h' | 'exclamation_outline' | 'pig' | 'filter-vertical']: IconsEnum;
  } = {
    info: IconsEnum.INFO,
    question: IconsEnum.ROUND_QUESTION,
    'autoesclusion-24h': IconsEnum.AUTOESCLUSION_24H,
    exclamation_outline: IconsEnum.ECLAMATION_MARK_OUTLINE,
    pig: IconsEnum.PIG,
    'filter-vertical': IconsEnum.FILTER_VERTICAL,
  };

  return (
    <Dialog
      isMobileFullScreen={isMobileFullScreen}
      open={dialogInfo.isOpen}
      onOpenChange={onOpenChange}
      isClosable={dialogInfo.isClosable}
    >
      <div className={styles.container}>
        {dialogInfo.icon && (
          <Icon
            iconId={typeIconDictionary[dialogInfo.icon]}
            className={`${styles.icon}`}
            color={
              dialogInfo.icon === 'info' ||
              dialogInfo.icon === 'question' ||
              dialogInfo.icon === 'autoesclusion-24h' ||
              dialogInfo.icon === 'exclamation_outline'
                ? 'var(--color-accent)'
                : ''
            }
          />
        )}
        <p className={styles.title}>{dialogInfo.title}</p>
        {dialogInfo.subtitle && <p className={styles.text}>{dialogInfo.subtitle}</p>}
        {dialogInfo.info && <p className={styles.info} dangerouslySetInnerHTML={{ __html: dialogInfo.info }}></p>}
        {children && children}
        {dialogInfo.buttons && dialogInfo.buttons.length > 0 && (
          <div className={`${styles.buttonsDialog} ${classNameContainerButtons ? classNameContainerButtons : ''}`}>
            {dialogInfo.buttons}
          </div>
        )}
      </div>
    </Dialog>
  );
};
