import { AnchorLink } from '../../../anchorLink';
import React from 'react';
import styles from './CardTitle.module.scss';
import { useRouter } from 'next/router';

export type CardTitleProps = {
  title: string;
  url?: string;
  className?: string;
};

export const CardTitle = ({ title, url, className }: CardTitleProps) => {
  const router = useRouter();
  const handleRouterPathname = (pathName: string) => {
    return pathName.replace('/[slug]', '').replace('/ricerca', '');
  };
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <React.Fragment>
      {url ? (
        <AnchorLink
          href={handleRouterPathname(router.pathname === '/' ? `/slot-top/${url}` : `${router.pathname}/${url}`)}
          className={`${styles.title}${className ? className : ''}`}
        >
          {title}
        </AnchorLink>
      ) : (
        <h4 className={`${styles.title} ${className ? className : ''}`}>{title}</h4>
      )}
    </React.Fragment>
  );
};
