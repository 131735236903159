import { Icon, IconsEnum } from '../../../Icons';
import { AnchorLink } from '../../../anchorLink';
import styles from './CardBonusChip.module.scss';

export type CardBonusChipProps = {
  url: string;
};

export const CardBonusChip = ({ url }: CardBonusChipProps) => {
  return (
    <div className={styles.container}>
      <AnchorLink href={url} className={styles.link}>
        <Icon iconId={IconsEnum.BONUS} className={styles.icon} color="var(--color-neutral-01)" />
      </AnchorLink>
    </div>
  );
};
