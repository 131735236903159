import { createContext, useContext } from 'react';
import { DatoCmsMightLike } from '../lib/datoCms/types';

export type MightLikesContext = DatoCmsMightLike[];

const MightLikeContext = createContext<MightLikesContext>([]);

export const useMightLike = () => {
  const mightLikesContext = useContext(MightLikeContext);

  return mightLikesContext;
};

export { MightLikeContext };
